<template>
  <div class="page-body">
    <div class="search-box">
      <a-button type="primary" @click="addEditor(false)">新建</a-button>
      <!--  搜索表单  -->
      <a-space align="start">
        <a-input
          v-model:value="state.query.producerName"
          allowClear
          placeholder="请输入厂家名称"
          style="width: 160px"
        />
        <a-input
          v-model:value="state.query.platformUrl"
          allowClear
          placeholder="请输入平台地址"
          style="width: 160px"
        />
        <a-select
          v-model:value="state.query.status"
          allowClear
          :options="[
            { value: '0', label: '禁用' },
            { value: '1', label: '启用' },
          ]"
          placeholder="请选择状态"
          style="width: 100px"
        />
        <a-button type="primary" @click="search">查询</a-button>
        <a-button @click="reset">重置</a-button>
      </a-space>
    </div>
    <div class="table-box">
      <TxTable
        storageKey="STORAGE_KEY_ADMIXTURES_BRAND"
        size="small"
        rowKey="id"
        :loading="state.loading"
        bordered
        :dataSource="state.tableData"
        :columns="columns"
        :scroll="{
          x: 'calc(var(--main-table-width))',
          y: 'calc(var(--main-table-height))',
        }"
        :pagination="false"
      />
    </div>

    <a-pagination
      class="pagination"
      v-model:current="state.pageinfo.current"
      :total="state.pageinfo.total"
      show-size-changer
      :defaultPageSize="state.pageinfo.defaultPageSize"
      @change="handlePageChange"
    />
    <ModalFrom ref="editModal" @reload="reload" />
  </div>
</template>

<script setup>
import { HANDLE, producerAPI } from "@/Api/index.js";
import { onMounted, reactive, ref } from "vue";
import ModalFrom from "./components/ModalFrom.vue";
import defaultColumns from "./columns.js";
const editModal = ref(null);
const state = reactive({
  tableData: [],
  loading: false,
  pageinfo: {
    current: 1,
    total: 0,
    defaultPageSize: 20,
    pageSize: 20,
  },
  query: {},
});

// 生命周期
onMounted(() => {
  search();
});

// 新增 编辑
const addEditor = (data) => {
  editModal.value.open(data);
};

// 禁用启用
const changeStatus = async ({ id, status }) => {
  producerAPI
    .modifyStatus({ id, status: { 0: 1, 1: 0 }[status] })
    .then(HANDLE.handleSuccess)
    .then(reload)
    .catch(HANDLE.handleError);
};

// 查询
const query = ({ current, pageSize }) => {
  return HANDLE.fullLoading(
    producerAPI
      .list({ page: current, pageSize, ...state.query })
      .then(({ data }) => {
        state.pageinfo.pageSize = data.pageSize;
        state.pageinfo.current = data.currentPage;
        state.pageinfo.total = data.totalCount;
        state.tableData = data.arr;
      }, HANDLE.handleError)
  );
};

// 页码或页大小变化
const handlePageChange = (current, pageSize) => {
  query({ current, pageSize });
};

// 刷新
const reload = () => {
  return query({ ...state.pageinfo });
};

// 搜索
const search = () => {
  query({ current: 1, pageSize: state.pageinfo.pageSize });
};

const reset = () => {
  Object.assign(state.query, {
    producerName: undefined,
    platformUrl: undefined,
    status: undefined,
  });
  search();
};

const columns = ref(defaultColumns({ changeStatus, addEditor }));
</script>
