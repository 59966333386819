import { Tag, Button, Space, Popconfirm } from "ant-design-vue";
export default function ({ changeStatus, delItem, addEditor }) {
  return [
    {
      title: "编号",
      dataIndex: "id",
      width: 40,
      ellipsis: true,
    },
    {
      title: "厂家名称",
      dataIndex: "producerName",
      width: 80,
      ellipsis: true,
    },
    {
      title: "类型",
      dataIndex: "type",
      align: "center",
      width: 80,
      customRender: ({ text }) => {
        return { 1: <Tag>物联网</Tag>, 2: <Tag>摄像头</Tag> }[text];
      },
    },
    {
      title: "平台地址",
      dataIndex: "platformUrl",
      width: 120,
      ellipsis: true,
    },
    {
      title: "账号",
      dataIndex: "account",
      width: 100,
      ellipsis: true,
    },
    {
      title: "密码",
      dataIndex: "password",
      width: 100,
      ellipsis: true,
    },
    {
      title: "联系人",
      dataIndex: "linkman",
      width: 80,
      ellipsis: true,
    },
    {
      title: "联系电话",
      dataIndex: "telphone",
      width: 100,
      ellipsis: true,
    },
    {
      title: "状态",
      dataIndex: "status",
      width: 40,
      fixed: "right",
      customRender: ({ text }) => {
        return {
          1: <Tag color="green">启用</Tag>,
          0: <Tag color="red">禁用</Tag>,
        }[text];
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "100px",
      align: "center",
      fixed: "right",
      customRender: ({ record }) => {
        return (
          <Space>
            <Popconfirm
              title={`确定要${{ 0: "启用", 1: "禁用" }[record.status]}么？`}
              cancelText="取消"
              okText="确定"
              onConfirm={() => changeStatus(record)}
            >
              <Button
                size="small"
                type="link"
                danger={{ 0: false, 1: true }[record.status]}
              >
                {{ 0: "启用", 1: "禁用" }[record.status]}
              </Button>
            </Popconfirm>
            <Button size="small" type="link" onClick={() => addEditor(record)}>
              编辑
            </Button>
          </Space>
        );
      },
    },
  ];
}
