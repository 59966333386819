<template>
  <a-modal
    :visible="visible"
    :title="title"
    :maskClosable="false"
    :closable="!loading"
    :confirmLoading="loading"
    :cancel-button-props="{ disabled: loading }"
    width="650px"
    @ok="handleOk"
    @cancel="cancel"
    okText="保存"
    cancelText="取消"
    destroyOnClose
  >
    <a-form
      ref="formRef"
      :model="formState"
      autocomplete="off"
      v-bind="formItemLayout"
    >
      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item
            label="厂家名称"
            name="producerName"
            :rules="[
              { required: true, message: '厂家名称不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.producerName"
              :maxlength="20"
              placeholder="请输入厂家名称"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="设备类型"
            name="type"
            :rules="[{ required: true, message: '请选择设备类型' }]"
          >
            <a-select
              class="input"
              v-model:value="formState.type"
              placeholder="请选择设备类型"
              :options="[
                { value: '1', label: '物联网' },
                { value: '2', label: '摄像头' },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            label="平台地址"
            name="platformUrl"
            :rules="[
              { required: true, message: '平台地址不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.platformUrl"
              :maxlength="50"
              placeholder="请输入平台地址"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="账号"
            name="account"
            :rules="[
              { required: true, message: '账号不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.account"
              :maxlength="20"
              placeholder="请输入账号"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="密码"
            name="password"
            :rules="[
              { required: true, message: '密码不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.password"
              :maxlength="20"
              placeholder="请输入密码"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="联系人" name="linkman">
            <a-input
              class="input"
              v-model:value="formState.linkman"
              :maxlength="10"
              placeholder="请输入联系人"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系电话" name="telphone">
            <a-input
              class="input"
              v-model:value="formState.telphone"
              :maxlength="11"
              placeholder="请输入联系电话"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系邮箱" name="email">
            <a-input
              class="input"
              v-model:value="formState.email"
              :maxlength="100"
              placeholder="请输入联系邮箱"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item name="status" label="是否启用" required>
            <a-switch
              v-model:checked="formState.status"
              checkedValue="1"
              unCheckedValue="0"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="备注" name="remarks">
            <a-input
              class="input"
              v-model:value="formState.remarks"
              :maxlength="500"
              placeholder="请输入备注"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup>
import { HANDLE, producerAPI } from "@/Api/index.js";
import { ref, defineEmits, defineExpose } from "vue";
const emits = defineEmits(["update:value", "reload"]);
const formRef = ref(null);
const loading = ref(false);
const isEdit = ref(false);
const title = ref("新增");
const visible = ref(false);
const formState = ref({
  account: undefined,
  linkman: undefined,
  password: undefined,
  platformUrl: undefined,
  producerName: undefined,
  status: undefined,
  telphone: undefined,
  type: undefined,
  email: undefined,
  remarks: undefined,
});

const open = (data) => {
  const id = data && data.id;
  if (id) {
    isEdit.value = true;
    title.value = "编辑";
    formState.value = data;
  } else {
    isEdit.value = false;
    title.value = "新增";
    formState.value = {
      account: undefined,
      linkman: undefined,
      password: undefined,
      platformUrl: undefined,
      producerName: undefined,
      status: "1",
      telphone: undefined,
      type: "1",
      email: undefined,
      remarks: undefined,
    };
  }
  visible.value = true;
};

const formItemLayout = {
  // labelCol: { span: 7 },
  labelCol: { style: { width: "80px" } },
};

const cancel = () => {
  formRef.value.resetFields();
  visible.value = false;
};

const finish = (res) => {
  formRef.value.resetFields();
  visible.value = false;
  emits("reload");
  return res;
};

const handleOk = async () => {
  if (loading.value) return;
  await formRef.value.validateFields();
  loading.value = true;
  producerAPI
    .save({ ...formState.value })
    .then(HANDLE.handlerSuccessMessage("保存成功"))
    .then(finish)
    .catch(HANDLE.handleErrorTag("保存失败"))
    .finally(() => {
      loading.value = false;
    });
};

defineExpose({
  open,
});
</script>

<style scoped>
.input {
  width: 100%;
}
</style>
